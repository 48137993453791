import * as React from 'react'

import LatestPosts from '../components/LatestPosts'
import Newsletter from '../components/Newsletter'
import Hero from '../components/Friends/Hero'
import Companies from '../components/Friends/Companies'
import Layout from '../layouts'

import SEO from '../components/SEO'

const PartnersPage: React.FC = () => {
  return (
    <Layout>
      <SEO />
      <Hero />
      <Companies />
      <LatestPosts message="partners" />
      <Newsletter />
    </Layout>
  )
}

export default PartnersPage
