import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'

import mergeEdges from '../../../utils/mergeEdges'
import { Container } from '../../shared/Grid'
import { Box, Flex } from '../../shared/Helpers'
import { Image } from '../../shared/Image'
import { Description, Title } from '../../shared/Typography'
import HeroOverlay from '../../shared/HeroOverlay'

export interface IHero {
  partners: {
    title: string
    description: string
    images: string[]
  }
}

export interface IProp {
  allFoundationYaml: {
    edges: {
      node: { partners: IHero }
    }
  }
}

const HeroPartners = () => {
  const { allFoundationYaml } = useStaticQuery(graphql`
    query HeroPartners {
      allFoundationYaml {
        edges {
          node {
            partners {
              title
              description
              images
            }
          }
        }
      }
    }
  `)

  const data: IHero | null = mergeEdges(allFoundationYaml)

  if (!data) {
    return null
  }

  const col = ['100%', '50%']
  const colImage = ['100%', '50%', '30%']
  const padd = ['0', '6em']

  return (
    <HeroOverlay>
      <Container>
        <Flex flexWrap="wrap" alignItems="center" px={[null, '0', '6em']}>
          <Box width={col} pr={padd} mb="2.5em">
            <Title mb="0.5em">{data.partners.title}</Title>
            <Description color="greyMedium">
              {data.partners.description}
            </Description>
          </Box>

          <Box width={colImage} ml={[0, 0, '10%']}>
            <Image path={data.partners.images[0]} alt={data.partners.title} />
          </Box>
        </Flex>
      </Container>
    </HeroOverlay>
  )
}

export default HeroPartners
