import styled, { css } from '../../styled'

export default styled.div`
  margin-bottom: 1em;
  margin-top: -7em;
  padding-top: 7em;
  border-radius: 4px;
  margin-left: 0.5em;
  margin-right: 0.5em;
  position: relative;

  ${({ theme: { breakpoints } }) => css`
    @media screen and (min-width: ${breakpoints[0]}) {
      min-height: 524px;
      margin-bottom: 7em;
    }
  `};

  &:after {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    background: linear-gradient(to bottom, #fafafa 0%, #f7f7f7 100%);
  }
`
