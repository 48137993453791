import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'

import Card from '../../shared/Card'
import { Container } from '../../shared/Grid'
import { Flex, Box } from '../../shared/Helpers'
import { Image } from '../../shared/Image'
import { Caption, Text } from '../../shared/Typography'
import mergeEdges from '../../../utils/mergeEdges'
import Link from '../../shared/Link'

export interface ICompanies {
  partners: {
    companies: Array<{
      title: string
      description: string
      icon: string
      cta: string
      ctaLink: string
    }>
  }
}

const SectionPartners = () => {
  const { allFoundationYaml } = useStaticQuery(graphql`
    query companiesPartners {
      allFoundationYaml {
        edges {
          node {
            partners {
              companies {
                title
                description
                icon
                cta
                ctaLink
              }
            }
          }
        }
      }
    }
  `)

  const data: ICompanies | null = mergeEdges(allFoundationYaml)

  if (!data) {
    return null
  }

  return (
    <Container zIndex={3}>
      <Flex
        mt={['2.5rem', '4em']}
        mb="6em"
        flexWrap="wrap"
        justifyContent="space-between"
      >
        {data.partners.companies.map(e => {
          const width = ['100%', 'calc((100% / 2) - .7em)']

          return (
            <Card mb="1.5em" width={width} key={e.title}>
              <Flex alignItems="center">
                <Box mr="16px" mb="16px" width="40px" height="40px">
                  <Image path={e.icon} alt={e.title} />
                </Box>
                <Caption mb="1rem">{e.title}</Caption>
              </Flex>
              <Text color="grey">{e.description}</Text>
              {e.ctaLink && e.cta && (
                <Link mt="1.5rem" color="blue" to={e.ctaLink} withIcon="arrow">
                  {e.cta}
                </Link>
              )}
            </Card>
          )
        })}
      </Flex>
    </Container>
  )
}

export default SectionPartners
